import { TeamPropsInterface } from '../../../interfaces/team-props.interface';

const teamData: TeamPropsInterface[] = [
  {
    name: `NAFTALI GROUP`,
    position: 'DEVELOPER',
    link: 'https://naftaligroup.com/',
  },
  {
    name: 'ROBERT A.M. STERN ARCHITECTS',
    position: 'DESIGN ARCHITECT',
    link: 'https://www.ramsa.com/',
  },
  {
    name: 'ACHILLE SALVAGNI',
    position: 'INTERIOR DESIGN',
    link: 'https://www.achillesalvagni.com/',
  },
  {
    name: 'COMPASS DEVELOPMENT MARKETING GROUP',
    position: 'MARKETING AND SALES',
    link: 'https://www.compass.com/',
  },
  {
    name: 'DBOX',
    position: 'BRANDING AND CREATIVE',
    link: 'https://www.dbox.com/',
  },
];

export default teamData;
