import { FooterPropsInterface } from '../../interfaces/footer-props.interface';

const footerData: FooterPropsInterface = {
  street: '1165 Madison Avenue',
  city: 'New York NY',
  code: 10028,
  phone: '2127641165',
  phoneText: '212 764 1165',
  email: 'info@the-bellemont.com'
};

export default footerData;
