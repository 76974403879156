import { PageContentProps } from '../../../interfaces/page-content-props.interface';

const residencesContentData: PageContentProps = {
  title: `THE LIGHT AND LIFE OF THE UPPER EAST SIDE ENTERS THE GRAND-SCALE RESIDENCES THROUGH PALATIAL WINDOWS ANDTHE
          PRIVATE BEAUTY OF EACH RESIDENCE IS ILLUSTRATED WITH GRACE AND SCALE.`,
  description: `Stern and Salvagni have planned each residence as its own unique arrangement of perfect rooms for
                gracious living and the details complete the story. eavy, large scale, polished doors and tall moldings
                create balance. Windows with spans of glass coupled with eye candy shapes and detail create magic.
                Alt option: Windows with expansive spans of glass frame idyllic scenes of the Upper East Side and
                Central Park. Terraces set the stage for remarkable respites. The architects and designers have,
                remarkably, createdmoments of exquisite delight - the sweep of a spiral staircase with a breathtaking
                view, a centered soaking tub with a view to the sky, or a wall of glass front cabinets that seems to
                marry decades past with the current moment.`,
};

export default residencesContentData;
