// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amenities-tsx": () => import("./../../../src/pages/amenities.tsx" /* webpackChunkName: "component---src-pages-amenities-tsx" */),
  "component---src-pages-architecture-tsx": () => import("./../../../src/pages/architecture.tsx" /* webpackChunkName: "component---src-pages-architecture-tsx" */),
  "component---src-pages-availability-tsx": () => import("./../../../src/pages/availability.tsx" /* webpackChunkName: "component---src-pages-availability-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-neighborhood-tsx": () => import("./../../../src/pages/neighborhood.tsx" /* webpackChunkName: "component---src-pages-neighborhood-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-residences-tsx": () => import("./../../../src/pages/residences.tsx" /* webpackChunkName: "component---src-pages-residences-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

