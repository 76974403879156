import { ILegal } from '../../interfaces/legal.interface';

const legalData: ILegal = {
  logoTitle: 'The Bellemont',
  logoSubtitle: '1165 Madison Avenue',
  contentTitle: 'LEGAL DISCLAIMER',
  content: `Images are a combination of photographs and artist renderings. The complete offering terms are in an offering plan available from the Sponsor. File No. 21-0058. The artist representations and interior decorations, finishes, appliances and furnishings are provided for illustrative purposes only. Sponsor makes no representations or warranties except as may be set forth in the Offering Plan. Sponsor reserves the right to substitute materials, appliances, equipment, fixtures and other construction and design details specified herein as provided in the Offering Plan. All dimensions are approximate and subject to normal construction variances and tolerances. Square footage exceeds the usable floor area. Sponsor reserves the right to make changes in accordance with the terms of the Offering Plan. Plans and dimensions may contain minor variations from floor to floor. Sponsor: 1165 Madison Ave Owner LLC c/o The Naftali Group, 152 West 57th Street, 45th Floor, New York, New York 10019. Equal Housing Opportunity.`,
  by: 'Renderings, branding and creative by DBOX.',
};

export default legalData;
